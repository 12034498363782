// FOOTER
// ------------------------------------------
footer { 
	@include floatWidth; 
	padding:53px 0; 
	background-color:$white; 
	color:$text-colour-dark;
	border-top: 2px #00b0e3 solid;
	
	a {
		color:$colour-primary;
		&:hover {
			text-decoration: none;
		}
	}
	
	p {
		margin:0;
	}
	
	small {
		font-size:16px;
		font-weight:500;
		display: block;
	}
	
	ul {  
		margin:0; 
		padding:0; 
		list-style:none;
		
		li { 
			list-style: none; 
			margin:0 0 13px 0;
			font-weight: 600;
		}
	}
	
	small {
		text-align: right;
	}
	
}

/* Extra Small Devices, Tablets */
@media only screen and (max-width : 767px) {
	footer { 	
		small {
			text-align: left;
		}
	}
}
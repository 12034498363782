// BREADCRUMBS
// ------------------------------------------
#breadcrumbs {
	@include floatWidth;
	background: $white;
	padding: 14px 0;
	color: $text-colour-dark;
	span {
		font-weight: 600;
	}	
		
	a {
		color: $text-colour-dark;
		span {
			font-weight: 400;
		}	
	}
}
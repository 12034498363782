// MIXINS
// ------------------------------------------

/* Add float left & width 100% */
@mixin floatWidth {
	float:left; 
	width:100%;
}
/* Add float left & width 100% */


/* Set background image to covers element */
@mixin backgroundImageCover {
	background-repeat:no-repeat; 
	background-position:center center;  
	-webkit-background-size: cover; 
	-moz-background-size: cover; 
	-o-background-size: cover; 
	background-size: cover;
}
/* Set background image to covers element */


/* Add a small shadow to text */
@mixin smallTextShadow {
  text-shadow: 0px 1px 3px rgba(0,0,0,.3);
}
/* Add a small shadow to text */


/* Add a shadow to non-text element */
@mixin boxShadow {
  box-shadow: 0 2px 4px rgba(0,0,0,.16);
  transition: all .3s ease;
  &:hover {
    transform: translateY(-1.5px);
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
    cursor: pointer;
  }
}
/* Add a shadow to non-text element */


/* Add a linear-gradient */
@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}
/* Add a linear-gradient */
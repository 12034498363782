// Variables
// --------------------------------------------------

$font-stack: 				"Source Sans Pro", sans-serif;
$font-size-base:			16px !default;
$line-height-base:  		1.5 !default;
$line-height-computed:		floor(($font-size-base * $line-height-base)) !default;

$screen-sm:          		768px !default;
$screen-sm-min:      		$screen-sm !default;

$colour-primary: 			$blue;
$colour-secondary: 			green;
$colour-tertiary: 			blue;
$colour-quaternary:			blueviolet;

$text-colour-dark: 			$black;
$text-colour-light: 		$white;
$text-colour-highlight-1: 	purple;
$text-colour-highlight-2: 	orange;
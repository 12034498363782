// Latest Articles
// --------------------------------------------------

#latest-articles {
	padding: 52px 0 68px;
	width: 100%;
    float: left;
    h2 {
	    font-size: 36px;
	    text-align: center;
	    margin-top: 0px;
    }
    .article-panel {
	    margin: 20px 0 0 0;
    }
    .no-articles {
    	text-align: center;
	}
	.pagination {
    	width: 100%;
    	a:last-child {
			text-align: right;
			display: block;
		}
	}
}
// Article Panels
// --------------------------------------------------

.article-panel {
	background: $white;
	border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
    transition: 0.3s;
    margin-bottom: 45px;
	
	&:hover {
	    -webkit-transform: translateY(-2px);
	    transform: translateY(-2px);
	    box-shadow: 0 4px 8px rgba(0,0,0,.24);
	    cursor: pointer;
	}
	
	.article-panel-featured-image {
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 242px;
		border-radius: 10px 10px 0 0;
	}
	
	.article-panel-text {
		padding: 0 20px 28px 20px;
		height: 165px;
		
		p {
	    	color: $text-colour-dark;
		}
		a.view-article, 
		a.export,
		a.embed-game {
			color: $colour-primary;
			text-transform: uppercase;
			font-weight: 700;
		}
		a.export {
			display: block;
			float: right;
		}
		a.embed-game {
			display: block;
			margin-top: 10px;
		}
	}
	
	h2, h3 {
		color: $text-colour-dark;
		font-size: 21px;
		font-weight: 600;
	}
	
	a:hover {
	    text-decoration: none;
	}
}

/* Small Devices, Desktops */
@media only screen and (max-width : 991px) {	
	.article-panel .article-panel-featured-image {
	    height: 165px;
	}
}

/* Extra Small Devices, Tablets */
@media only screen and (max-width : 767px) {
	.article-panel {
		width: 100%;
		float: left;
	}
	.article-panel .article-panel-featured-image {
		width: 40%;
		height: 144px;
		border-radius: 10px 0px 0 10px;
		float: left;
	}
	.article-panel .article-panel-text {
		padding: 0 20px 20px 20px;
		float: left;
		width: 60%;
	}
	.article-panel-summary {
		display: none !important;
	}
}
// WP-ACTIVATE.PHP
// ------------------------------------------
#signup-content { 
	width: 100%;
    float: left;
    margin: 0 0 50px 0;
	
	.wp-activate-container {
		text-align: center;
		
		h2 {
			width: 100%;
			padding: 48px 15px;
			background-color: #00b0e3;
			margin-bottom: 16px;
			font-size: 50px;
			font-weight: 700;
			color: #fff;
		}
		
		#signup-welcome {
			padding: 0 15px;
			margin: 18px 15px;
			font-size: 30px;
			
			p {
				margin: 0 15px;
				
				.h3 {
					padding: 0 8px 0 0;
				    font-size: 30px;
				    font-weight: 600;
				}
			}
		}
		
		.view {
			margin: 0 15px;
			padding: 0 15px;
			font-size: 21px;
		}
	}
}
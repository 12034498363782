// Header
// --------------------------------------------------

// HEADER
// ------------------------------------------
header { 
	@include floatWidth; 
	padding: 18px 0;
	background: white;
	padding: 24px 0;
	
	.menu-main-menu-container, 
	.menu-frontend-menu-logged-in-container, 
	.menu-backend-menu-logged-in-container, 
	.menu-right-menu-container, 
	.menu-frontend-right-menu-logged-in-container, 
	.menu-backend-right-menu-logged-in-container { 
		width: auto; 
		margin-top: 10px;
		
		ul {  
			margin: 0px; 
			padding: 0; 
			list-style: none; 
			float: right;
	    	
	    	li { 
		    	display: inline-block; 
		    	text-align: right; 
		    	text-decoration: none; 
		    	font-size: 16px; 
		    	color: #4C697B; 
		    	list-style: none; 
		    	font-weight: 500; 
		    	margin-left: 0px;
		    	margin-right: 41px;
		    	height: 30px;
		    	
		    	&:first-child{
			    	margin-left: 0px;
		    	}
				
				a { 
					color: #4C697B;
					text-transform: uppercase;
					display: block;
				}
	    	}
		}
		
		#menu-main-menu .current-menu-item a,
		#menu-main-menu .current-page-item a,
		#menu-frontend-menu-logged-in .current-menu-item a,
		#menu-frontend-menu-logged-in .current-page-item a, {
		    color: white;
		    background: #132C37;
		    padding: 4px 11px;
		    border-radius: 6px;
		}
	}
}

//********** Top Nav Sub Menu
ul#menu-top_nav li:hover { 
	.sub-menu {
		visibility:visible; 
		opacity:1;
	}
}

/******** Positioning individual sub-menu
li#menu-item-### { 
	.sub-menu {
		margin-left:-28px;
	} 
}
*/

.menu-top_nav-container ul.sub-menu { 
	margin:0; 
	background:#ffffff; 
	border-radius: 16px; 
	padding:10px 0; 
	
	li {
    	margin:0; 
    	text-align: left; 
    	width:100%; 
    	display:block; 
		
		a {
			display:block; 
			padding:6px 30px; 
			color:$colour-primary;
		}
	} 
}
//********** Top Nav Sub Menu


//********** Mobile Nav
body.modal-open {
// 	position: fixed;
	width: 100%;
	height: 100%;
}

.hamburger {
	float:right;
	padding: 0;
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 30px 0 0 0;
	overflow: visible; 
	&:hover {
		background:transparent!important; 
		opacity: 0.7; 
	}
	&:focus {
		outline:none; 
		background:transparent!important;
	}
}

.hamburger-box {
	width: 30px; 
	height: 16px; 
	display: inline-block; 
	position: relative; 
	top:8px;
}

.hamburger-inner { 
	display: block; 
	top: 50%; 
	margin-top: -2px; 
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
	width: 28px;
	height: 2px;
	background-color: $text-colour-light;
	border-radius: 4px;
	position: absolute;
	transition-property: transform; transition-duration: 0.15s; transition-timing-function: ease; 
}
.hamburger-inner::before, .hamburger-inner::after { 
	content: "";
	display: block; 
}
.hamburger-inner::before {
	top: -10px; 
}
.hamburger-inner::after {
	bottom: -10px;
} 

/** Collapse */
.hamburger--collapse .hamburger-inner {
	top: auto; 
	bottom: 0;
	transition-duration: 0.13s;
	transition-delay: 0.13s;
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner::after { 
	top: -20px; 
	transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; 
}
.hamburger--collapse .hamburger-inner::before { 
	transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); 
}

.hamburger--collapse.is-active .hamburger-inner { 
	background-color: white;
	transform: translate3d(0, -10px, 0) rotate(-45deg);
	transition-delay: 0.22s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); 
}
.hamburger--collapse.is-active .hamburger-inner::after { 
	top: 0; 
	opacity: 0; 
	background-color: white; 
	transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; 
}
.hamburger--collapse.is-active .hamburger-inner::before { 
	top: 0; 
	background-color: white;
	transform: rotate(-90deg); 
	transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); 
}

#navModal { 
	background-color: rgba($colour-primary, 0.95) !important; 
	color:white; 
	text-align: left; 
	overflow-y: hidden; 
	.close-white {
		margin:15px 25px 0 0; 
		color:white;
		opacity:0.9;
		&:hover {
			opacity:1;
		}
	}
	ul { 
		padding: 0; 
		margin:50px 0 0 0; 
		list-style: none; 
		text-align: center;
		li { 
			margin:25px 0; 
			font-size:24px; 
			font-family: $font-stack;
			text-transform: uppercase;
			font-weight: 600;
			a {
				color:white;
				&:hover {
					text-decoration: none;
				}
			}
			&.current_page_item a {
				color: $text-colour-highlight-2;
			}
		}
	}
}

.modal-backdrop { 
	z-index: 1 !important; 
	background-color: rgba($colour-primary, 0.95) !important; 
}
.modal:before { 
	height: auto;
}
//********** Mobile Nav


/* Medium Devices, Desktops */
@media only screen and (max-width : 1200px) {
	header .menu-top_nav-container ul li {
		margin-left: 10px;
	}
}


/* Small Devices, Desktops */
@media only screen and (max-width : 991px) {


}


/* Extra Small Devices, Tablets */
@media only screen and (max-width : 767px) {
 
}


/* Extra Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {


}
// DEFAULT PAGE
// ------------------------------------------
.page-template-default {
	
	#page-title {
		padding: 48px 0;
		margin-bottom: 16px; 
		
		h1 {
			margin: 0;
			font-size: 50px;
			font-weight: 700;
		}
	}
	
	h4 {
		font-size: 28px;
		font-weight: 700;
		color: #00b0e3;
		margin-top: 25px;
	}
}
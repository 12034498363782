// SOCIAL MEDIA
// ------------------------------------------
#social-media-links {
	text-align: right;
}

#social-media-links {
	margin-bottom: 30px;
	a {
		margin-left: 31px;
		&:nth-child(1){
			margin-left: 0;
		}
	}
}

/* Extra Small Devices, Tablets */
@media only screen and (max-width : 767px) {	
	#social-media-links {
		margin-top: 30px;
	}
	
	#social-media-links {
		text-align: left;
	}
}
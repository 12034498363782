/* `Basic HTML
----------------------------------------------------------------------------------------------------*/

body {
  font: 16px/1.4 $font-stack !important;
  -webkit-font-smoothing: antialiased; /*fixes rendering problems*/
  -moz-osx-font-smoothing: grayscale;
  color:$text-colour-dark;
  font-weight:400;
}

a {
  text-decoration: none;
  color:$text-colour-highlight-1;
}
a:hover {
  text-decoration: underline;
  color:$text-colour-highlight-2;
}

em	{
	font-style: italic;
}

p,
dl,
hr,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
pre,
table,
address,
fieldset,
figure {
  margin-bottom: 15px;
}

small{
  font-size:13px;
}

blockquote {
	position: relative;
	padding: 0;
	margin: 0px 0 30px 49px;
	font-size: 27px;
	font-style: italic;
	border-left: 0px;
	color: $colour-primary;
}

blockquote:before {
	position: absolute;
	content: open-quote;
	font-size: 76px;
	line-height: .1em;
	top: 33px;
	left: -55px;
	float: left;
}

blockquote p {
	display: inline;
}


/* `Headings
----------------------------------------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6	{
	font-weight:500;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 21px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}


/* `Spacing
----------------------------------------------------------------------------------------------------*/

ol {
  list-style: decimal;
}

ul {
  list-style: disc;
}

/******* List style using image *
ul {
	list-style: disc inside url("../images/icons/circle-green.svg");
	list-style-position: outside;
	padding-left: 19px;
}
******* List style using image */

li {
  margin-left: 20px;
}
